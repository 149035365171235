import { useAPI } from "~/uses/useMyFetch";
import { useMemoize } from "@vueuse/core";
import { Product } from "~/models/product.model";
import { trackEvent } from "~/utils/trackEvent";

export const useFavoriteProductStore = defineStore("favoriteProductStore", {
  state: () => {
    return {
      products: [],
      memoizedFetch: null,
    };
  },
  actions: {
    async forceFetch() {
      useAPI("/catalog/product/list/favorite").then((data) => {
        this.products = data.map(({ product }) => new Product(product));
      });
    },
    async fetch() {
      if (null === this.memoizedFetch) {
        this.memoizedFetch = useMemoize(this.forceFetch);
      }

      return this.memoizedFetch();
    },
    async addProduct(product) {
      await useAPI("/catalog/product/favorite/add", {
        method: "POST",
        body: {
          productId: product.id,
        },
      }).then(() => {
        const event = {
          event: "add_to_wishlist",
          ecommerce: {
            items: [
              {
                item_name: `${product.code} ${product.name.uk}`,
                item_id: product.code,
                price: product.getPrice(Product.PRICE_TYPE.CURRENT),
                item_brand: product.manufacturer.name,
                item_category: `${product.getMainCategory().code} ${
                  product.getMainCategory().name.uk
                }`,
                item_list_name: product.getExtra("purchaseOrigin"),
                quantity: 1,
              },
            ],
          },
        };

        trackEvent(event);
      });

      await this.forceFetch();
    },
    async removeProduct(productId) {
      await useAPI("/catalog/product/favorite/remove", {
        method: "POST",
        body: { productId },
      });

      await this.forceFetch();
    },
    resetFavorites() {
      this.products = [];
    },
  },
  getters: {
    getIsProductInList: (state) => {
      return (id) => {
        return state.products.some((product) => product.id === id);
      };
    },
    getProducts: (state) => state.products,
    getProductsLength: (state) => state.products.length,
  },
});
